import { useEffect, useRef } from 'react';
import { Container, Tab, Row, Col } from 'react-bootstrap';
import GraphComponent from '../components/Inventory/Graph';
import { useInventoryTabs } from '../context/InventoryTabContext';
import { useQlik } from '../hooks/QlikProvider';
import QlikConfig from '../qlik.config.dev.json';

export default function Defects() {
  const qlik = useQlik();
  const { key } = useInventoryTabs();

  const defectiveLogsOverTime = useRef<HTMLDivElement>(null);
  const typeOfDefectRef = useRef<HTMLDivElement>(null);
  const numberOfDefectiveLogsOverTimeRef = useRef<HTMLDivElement>(null);

  const render = async () => {
    if (qlik.nebula != null) {
      if (defectiveLogsOverTime.current != null) {
        qlik.nebula.render({
          element: defectiveLogsOverTime.current,
          id: QlikConfig.defectiveLogsOverTimeComparedTo2PercentGoal
        });
      }
      if (typeOfDefectRef.current != null) {
        qlik.nebula.render({
          element: typeOfDefectRef.current,
          id: QlikConfig.typeOfDefectsInInventory
        });
      }
      if (numberOfDefectiveLogsOverTimeRef.current != null) {
        qlik.nebula.render({
          element: numberOfDefectiveLogsOverTimeRef.current,
          id: QlikConfig.numberOfDefectiveLogsInInventoryOverDefectType
        });
      }
    }
  };

  useEffect(() => {
    render();
  }, [qlik]);

  return (
    <Container fluid className="p-3">
      <Tab.Container activeKey={key}>
        <Row className="g-2">
          <Col xs={12} xl={6}>
            <GraphComponent>
              <Container fluid ref={defectiveLogsOverTime} className="w-100 h-100" />
            </GraphComponent>
          </Col>
          <Col xs={12} xl={6}>
            <GraphComponent>
              <Container fluid ref={typeOfDefectRef} className="w-100 h-100" />
            </GraphComponent>
          </Col>
          <Col xs={12}>
            <GraphComponent>
              <Container fluid ref={numberOfDefectiveLogsOverTimeRef} className="w-100 h-100" />
            </GraphComponent>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}
