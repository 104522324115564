import logo from '../images/tracy.png';
import { Component } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import headerStyle from '../styles/header.module.css';

class HeaderComponent extends Component {
  render() {
    return (
      <Navbar className={headerStyle.header} collapseOnSelect expand="lg" fixed="top">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img
              src={logo}
              className={`d-inline-block align-top ${headerStyle.logo}`}
              alt="Tracy logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }
}

export default HeaderComponent;
