import enigma from 'enigma.js';
import schema from 'enigma.js/schemas/12.936.0.json';
import { Auth, AuthType, Config } from '@qlik/sdk';
import { embed, stardust } from '@nebula.js/stardust';
import barchart from '@nebula.js/sn-bar-chart';
import piechart from '@nebula.js/sn-pie-chart';
import kpi from '@nebula.js/sn-kpi';
import linechart from '@nebula.js/sn-line-chart';
import table from '@nebula.js/sn-table';
import filterpane from '@nebula.js/sn-filter-pane';
import combochart from '@nebula.js/sn-combo-chart';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { backend } from '../utils';
import QlikConfig from '../qlik.config.dev.json';

interface Props {
  children: JSX.Element;
}

const QlikContext = createContext<{
  nebula: stardust.Embed | null;
}>({ nebula: null });

export const QlikProvider = (props: Props) => {
  const { user } = useAuth();

  const configure = embed.createConfiguration({
    load: () => Promise.resolve(barchart),
    context: {
      theme: 'light',
      language: 'en-US'
    },
    types: [
      {
        name: 'barchart',
        load: () => Promise.resolve(barchart)
      },
      {
        name: 'piechart',
        load: () => Promise.resolve(piechart)
      },
      {
        name: 'kpi',
        load: () => Promise.resolve(kpi)
      },
      {
        name: 'linechart',
        load: () => Promise.resolve(linechart)
      },
      {
        name: 'table',
        load: () => Promise.resolve(table)
      },
      {
        name: 'filterpane',
        load: () => Promise.resolve(filterpane)
      },
      {
        name: 'combochart',
        load: () => Promise.resolve(combochart)
      }
    ]
  });

  const connect = async () => {
    const useJwt = true;

    const WebIntegrationConfig: Config = {
      authType: AuthType.WebIntegration,
      webIntegrationId: QlikConfig.webIntegrationId,
      host: QlikConfig.host,
      autoRedirect: true
    };

    const JwtConfig: Config = {
      authType: AuthType.JWTAuth,
      webIntegrationId: QlikConfig.webIntegrationId,
      host: QlikConfig.host,
      fetchToken: () => {
        if (user != null) {
          return backend().getQlikJwt(user.id, user.token);
        }
        return Promise.reject('');
      }
    };

    const authInstance = useJwt ? new Auth(JwtConfig) : new Auth(WebIntegrationConfig);

    if (useJwt) {
      await authInstance.getSessionCookie();
    } else {
      if (!authInstance.isAuthenticated()) {
        authInstance.authenticate();
      }
    }

    const url = await authInstance.generateWebsocketUrl(QlikConfig.docId);
    const enigmaGlobal = await enigma.create({ schema, url }).open<EngineAPI.IGlobal>();
    return enigmaGlobal.openDoc(QlikConfig.docId);
  };

  const [nebula, setNebula] = useState<stardust.Embed | null>(null);

  const init = async () => {
    const enigma = await connect();
    const _nebula = configure(enigma);
    setNebula(_nebula);
  };

  useEffect(() => {
    if (user != null) {
      init();
    }
  }, [user]);

  return (
    <QlikContext.Provider
      value={{
        nebula
      }}
    >
      {props.children}
    </QlikContext.Provider>
  );
};

export const useQlik = () => {
  return useContext(QlikContext);
};
