import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Card, CloseButton, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Stations } from '../../routes/LogListInfo';

interface InfoFrameProps {
  id: string;
  order: number;
  icon: IconDefinition;
  name: Stations;
  children: JSX.Element[] | JSX.Element;
}

export const InfoFrame: FC<InfoFrameProps> = (props: InfoFrameProps) => {
  const navigate = useNavigate();

  const onClose = () => {
    navigate(-1);
  };

  return (
    <Container fluid className="p-3 map-info-container">
      <Card className="h-100">
        <Card.Header className="text-center" as={'h5'}>
          <FontAwesomeIcon icon={props.icon} />{' '}
          {props.name !== 'Harvesting'
            ? `Station ${props.order}: ${props.name}`
            : 'Harvesting Site'}
          <CloseButton className="float-end" onClick={onClose} />
        </Card.Header>
        <Card.Body as={Row} className="h-100 align-items-center">
          {props.children}
        </Card.Body>
      </Card>
    </Container>
  );
};
