import React, { FC, useState } from 'react';
import { Button, Modal, Form, Row, Col, InputGroup, FormGroup } from 'react-bootstrap';
import logListStyle from '../../styles/logList.module.css';
import '../../routes/LogList';
import { Species } from '../../routes/LogList';

export interface LogListFilterValues {
  start_date?: string;
  end_date?: string;
  min_quality?: string;
  max_quality?: string;
  min_diameter?: string;
  max_diameter?: string;
  min_length?: string;
  max_length?: string;
  min_stations?: string;
  max_stations?: string;
  min_compartment?: string;
  max_compartment?: string;
  site?: string;
  species?: Species;
  work_order?: string;
}

interface FilterModalProps {
  show: boolean;
  initialState: URLSearchParams;
  handleClose: () => void;
  handleSubmit: (filter: LogListFilterValues) => void;
}

const LogListFilterModal: FC<FilterModalProps> = (props: FilterModalProps) => {
  const [state, setState] = useState({
    start_date: props.initialState.get('start_date') ?? '',
    end_date: props.initialState.get('end_date') ?? '',
    min_quality: props.initialState.get('min_quality') ?? '',
    max_quality: props.initialState.get('max_quality') ?? '',
    min_diameter: props.initialState.get('min_diameter') ?? '',
    max_diameter: props.initialState.get('max_diameter') ?? '',
    min_length: props.initialState.get('min_length') ?? '',
    max_length: props.initialState.get('max_length') ?? '',
    min_stations: props.initialState.get('min_stations') ?? '',
    max_stations: props.initialState.get('max_stations') ?? '',
    min_compartment: props.initialState.get('min_compartment') ?? '',
    max_compartment: props.initialState.get('max_compartment') ?? '',
    site: undefined,
    species: props.initialState.get('species') ?? 'All species',
    work_order: props.initialState.get('work_order') ?? ''
  });
  const [intialState, setInitialState] = useState(state);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((values) => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState((values) => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const onOpen = () => {
    setInitialState(state);
  };

  const onClose = () => {
    setState(intialState);
    props.handleClose();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const filterValues: LogListFilterValues = {
      start_date: state.start_date ? state.start_date : undefined,
      end_date: state.end_date ? state.end_date : undefined,
      min_quality: state.min_quality ? state.min_quality : undefined,
      max_quality: state.max_quality ? state.max_quality : undefined,
      min_diameter: state.min_diameter ? state.min_diameter : undefined,
      max_diameter: state.max_diameter ? state.max_diameter : undefined,
      min_length: state.min_length ? state.min_length : undefined,
      max_length: state.max_length ? state.max_length : undefined,
      min_stations: state.min_stations ? state.min_stations : undefined,
      max_stations: state.max_stations ? state.max_stations : undefined,
      min_compartment: state.min_compartment ? state.min_compartment : undefined,
      max_compartment: state.max_compartment ? state.max_compartment : undefined,
      site: state.site,
      species: state.species === 'All species' ? undefined : (state.species as Species),
      work_order: state.work_order
    };

    props.handleSubmit(filterValues);
    props.handleClose();
  };

  return (
    <Modal
      show={props.show}
      onHide={onClose}
      onEnter={onOpen}
      className={logListStyle.modal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className={logListStyle.mainTitle}>Filter By</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <FormGroup as={Row} className="mb-3">
            <p>Time Span Activity</p>
            <Col>
              <Form.Label>From: </Form.Label>
              <Form.Control
                type="date"
                id="start_date"
                name="start_date"
                onChange={onInputChange}
                value={state.start_date}
              />
            </Col>
            <Col>
              <Form.Label>To: </Form.Label>
              <Form.Control
                type="date"
                id="end_date"
                name="end_date"
                min={state.start_date}
                onChange={onInputChange}
                value={state.end_date}
              />
            </Col>
          </FormGroup>
          <FormGroup as={Row} className="mb-3">
            <Col>
              <Form.Select
                id="species"
                name="species"
                onChange={onSelectChange}
                value={state.species}
              >
                <option>All species</option>
                <option>Spruce</option>
                <option>Pine</option>
              </Form.Select>
            </Col>
          </FormGroup>
          <Row>
            <FormGroup as={Col}>
              <Form.Label>Quality</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="min_quality_label">Min:</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="min_quality"
                  name="min_quality"
                  onChange={onInputChange}
                  value={state.min_quality}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="max_quality_label">Max:</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="max_quality"
                  name="max_quality"
                  onChange={onInputChange}
                  value={state.max_quality}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup as={Col}>
              <Form.Label>Diameter</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="min_diameter_label">Min:</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="in mm"
                  id="min_diameter"
                  name="min_diameter"
                  onChange={onInputChange}
                  value={state.min_diameter}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="max_diameter_label">Max:</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="in mm"
                  id="max_diameter"
                  name="max_diameter"
                  onChange={onInputChange}
                  value={state.max_diameter}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup as={Col}>
              <Form.Label>Length</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="min_length_label">Min:</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="in cm"
                  id="min_length"
                  name="min_length"
                  onChange={onInputChange}
                  value={state.min_length}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="max_length_label">Max:</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="in cm"
                  id="max_length"
                  name="max_length"
                  onChange={onInputChange}
                  value={state.max_length}
                />
              </InputGroup>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup as={Col}>
              <Form.Label>Stations</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="min_stations_label">From:</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="min_stations"
                  name="min_stations"
                  onChange={onInputChange}
                  value={state.min_stations}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="max_stations_label">To:</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="max_stations"
                  name="max_stations"
                  onChange={onInputChange}
                  value={state.max_stations}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup as={Col}>
              <Form.Label>Compartment</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="min_compartment_label">From:</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="min_compartment"
                  name="min_compartment"
                  onChange={onInputChange}
                  value={state.min_compartment}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="max_compartment_label">To:</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="max_compartment"
                  name="max_compartment"
                  onChange={onInputChange}
                  value={state.max_compartment}
                />
              </InputGroup>
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="submit">
            Filter
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default LogListFilterModal;
