import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, SetStateAction, useEffect, useContext } from 'react';
import { Card, Row, Col, InputGroup, Form, Table } from 'react-bootstrap';
import logListInfoStyle from '../../styles/logListInfo.module.css';
import { LogContext } from '../../context/LogContext';
import { backend } from '../../utils';
import { useAuth } from '../../hooks/AuthProvider';
import format from 'date-fns/format';

export interface LogComment {
  id: string;
  content: string;
  userId: string;
  logId: string;
  created: string;
  user: { id: string; name: string };
}

export default function LogComments() {
  const logContext = useContext(LogContext);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<LogComment[]>();
  const { user } = useAuth();

  const handleCommentChange = (event: { target: { value: SetStateAction<string> } }) => {
    setComment(event.target.value);
  };

  /* add comment */
  const handleEnterKey = (event: { key: string }) => {
    if (event.key === 'Enter') {
      const addComment = async () => {
        if (user?.token && logContext?.log?.id) {
          await backend().addNewComment(logContext.log?.id, comment, user?.token);
          fetchComments();
        }
      };
      addComment();
    }
  };

  /* search a comment */
  const [filteredComments, setFilteredComments] = useState<
    {
      id: string;
      content: string;
      userId: string;
      logId: string;
      created: string;
      user: { id: string; name: string };
    }[]
  >();

  const filterComments = (searchQuery: string) => {
    const filtered = comments?.filter((comment) =>
      comment.content.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredComments(filtered);
  };

  /* display all comments */
  const fetchComments = async () => {
    if (user?.token && logContext?.log?.id) {
      const logComments = (await backend().listComments(logContext.log.id, user.token)).reverse();
      setComments(logComments);
      setFilteredComments(logComments);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Card.Title>Comments</Card.Title>
        </Col>
        <Col xs="auto">
          <InputGroup className={logListInfoStyle.inputGroup}>
            <InputGroup.Text>
              <FontAwesomeIcon className={logListInfoStyle.search} icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search..."
              onChange={(e) => filterComments(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <br></br>
      <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
        <Form.Control
          className="text-center"
          placeholder="Type your comment here..."
          style={{ height: '50px', border: 'none' }}
          value={comment}
          onChange={handleCommentChange}
          onKeyDown={handleEnterKey}
        ></Form.Control>
      </Row>
      <br></br>
      <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
        <Card
          style={{ border: 'none' }}
          className={`${logListInfoStyle.comments} text-center`}
          body
        >
          <div style={{ height: '200px', overflowY: 'auto' }}>
            {filteredComments && filteredComments.length > 0 ? (
              <Table style={{ fontSize: '0.9rem' }}>
                <thead className="sticky-top" style={{ backgroundColor: 'white' }}>
                  <tr>
                    <th>Time</th>
                    <th>User</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredComments.map((commentInfo) => (
                    <tr key={commentInfo.id}>
                      <td>{format(new Date(commentInfo.created), 'dd/MM/yyyy, h:mm:ss')}</td>
                      <td>{commentInfo.user.name}</td>
                      <td style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                        {commentInfo.content}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div>No available comments</div>
            )}
          </div>
        </Card>
      </Row>
    </>
  );
}
