import { FC } from 'react';

interface Props {
  stations: number;
}

export const LogListStationBars: FC<Props> = (props: Props) => {
  if (props.stations === 1) {
    return (
      <>
        <svg height="25" width="20" style={{ float: 'left' }}>
          <rect x="0" y="0" width="10" height="50" fill="#9DC001" />
        </svg>
        <svg height="25" width="20" style={{ float: 'left' }}></svg>
        {props.stations + '/2'}
      </>
    );
  } else {
    return (
      <>
        <svg height="25" width="20" style={{ float: 'left' }}>
          <rect x="0" y="0" width="10" height="50" fill="#9DC001" />
        </svg>
        <svg height="25" width="20" style={{ float: 'left' }}>
          <rect x="0" y="0" width="10" height="50" fill="#9DC001" />
        </svg>
        {props.stations + '/2'}
      </>
    );
  }
};
