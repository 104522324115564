import { format } from 'date-fns';
import { FC, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import { StationInfo } from '../../routes/LogListInfo';
import { LogImageModal } from './LogImageModal';

interface Props {
  station: StationInfo;
  date: Date;
}

export const LogImageCard: FC<Props> = (props: Props) => {
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <Card key={props.station.order} className="h-100">
      <Card.Body>
        <Card.Title style={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Station {props.station.order} [{props.station.name}]
        </Card.Title>

        <Image
          fluid
          src={props.station.image_url.toString()}
          style={{ cursor: 'pointer' }}
          onClick={() => setShowImageModal(true)}
        />

        <Card.Text style={{ fontSize: '1rem', fontWeight: 'bold' }}>
          {format(props.date, 'dd MMM yyyy, h:mm:ss')}
        </Card.Text>
      </Card.Body>
      <LogImageModal
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
        station={props.station}
      ></LogImageModal>
    </Card>
  );
};
