import { Modal, Button, Row, Col, Image, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { LogContext } from '../../context/LogContext';
import { useContext, useEffect, useState } from 'react';
import { faFilePen, faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { backend } from '../../utils';
import { LogInfo } from '../../routes/LogListInfo';
import { useAuth } from '../../hooks/AuthProvider';
import logListInfoStyle from '../../styles/logListInfo.module.css';

type CompareModalProps = {
  fullscreen: string | true | undefined;
  show: boolean;
  onHide: () => void;
};

CompareModal.propTypes = {
  show: PropTypes.bool.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default function CompareModal(props: CompareModalProps) {
  const [logData, setLogData] = useState<LogInfo>();
  const logContext = useContext(LogContext);
  const { user } = useAuth();

  /* getting log info */
  useEffect(() => {
    const fetchLogInfo = async () => {
      if (user?.token && logContext?.log?.id) {
        const logInfo = await backend().getLogInfo(logContext.log.id, user.token);
        setLogData(logInfo);
      }
    };

    fetchLogInfo();
  }, []);

  return (
    <Modal fullscreen={props.fullscreen} show={props.show} onHide={props.onHide}>
      {logData != null ? (
        <>
          <Modal.Header>
            <Nav.Link onClick={props.onHide}>
              <FontAwesomeIcon className={logListInfoStyle.backIcon} icon={faCircleLeft} />
            </Nav.Link>
            <Modal.Title
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              {logData.stations.map((station, index) =>
                index === 0 ? `Station ${station.order}` : ` & Station ${station.order}`
              )}
              <br /> Log {logContext.log?.id}{' '}
            </Modal.Title>
            <Button
              variant="secondary"
              onClick={props.onHide}
              size="lg"
              className="position-absolute top-0 end-0 m-3"
            >
              Close
            </Button>
          </Modal.Header>
          <Modal.Body
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Row>
              <Col md={5}>
                {logData.stations.map(
                  (station, index) =>
                    index % 2 === 0 && (
                      <div key={station.order} style={{ position: 'relative' }}>
                        <Image
                          fluid
                          src={station.image_url.toString()}
                          style={{ textAlign: 'center', justifyContent: 'center' }}
                        />
                        <input
                          type="checkbox"
                          style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            width: '30px',
                            height: '30px'
                          }}
                        />
                      </div>
                    )
                )}
              </Col>
              <Col
                md={2}
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  justifyContent: 'center'
                }}
              >
                <p style={{ color: '#E7502F', fontSize: '30px', fontWeight: 'bold' }}>
                  Select the desired pictures and report a mismatch
                </p>
                <Button variant="secondary" style={{ backgroundColor: 'white', border: 'none' }}>
                  <FontAwesomeIcon
                    icon={faFilePen}
                    style={{ color: '#E7502F', fontSize: '50px' }}
                  />
                </Button>
              </Col>
              <Col md={5}>
                {logData.stations.map(
                  (station, index) =>
                    index % 2 !== 0 && (
                      <div key={station.order} style={{ position: 'relative' }}>
                        <Image
                          fluid
                          src={station.image_url.toString()}
                          style={{ textAlign: 'center', justifyContent: 'center' }}
                        />
                        <input
                          type="checkbox"
                          style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            width: '30px',
                            height: '30px'
                          }}
                        />
                      </div>
                    )
                )}
              </Col>
            </Row>
          </Modal.Body>
        </>
      ) : (
        <>{'No images'}</>
      )}
    </Modal>
  );
}
