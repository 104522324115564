import { FC } from 'react';
import { Col, Form, Pagination, Row } from 'react-bootstrap';

interface Props {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  selectedOption: number;
  setSelectedOption: React.Dispatch<React.SetStateAction<number>>;
  totalRowsCount: number;
}

export const LogListPagination: FC<Props> = (props: Props) => {
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.setSelectedOption(parseInt(event.target.value));
    props.setCurrentPage(1);
  };

  const pageNumbers: Array<number | null> = [];
  const totalPages = Math.ceil(props.totalRowsCount / props.selectedOption);

  /* pagination */
  /* pageNumbers array contains the page numbers to display based on total pages and current page
   ** if total pages <=7, it adds all pages from 1 to totalPages iin pageNumbers
   ** if current page is within first 4 pages, it displays the first 5 pages followed by ellipses and then last 5 pages
   ** if current page is not in first 4 pages or last 3 pages then displays first 2 pages then ellipses then current page then pages immediatly before and after it then ellipses then last 2 pages */
  if (totalPages <= 7) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (props.currentPage <= 4) {
      for (let i = 1; i <= 5; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push(null);
      pageNumbers.push(totalPages - 1);
      pageNumbers.push(totalPages);
    } else if (props.currentPage >= totalPages - 3) {
      pageNumbers.push(1);
      pageNumbers.push(2);
      pageNumbers.push(null);
      for (let i = totalPages - 4; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push(2);
      pageNumbers.push(null);
      for (let i = props.currentPage - 1; i <= props.currentPage + 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push(null);
      pageNumbers.push(totalPages - 1);
      pageNumbers.push(totalPages);
    }
  }

  const handlePageChange = (page: number) => {
    props.setCurrentPage(page);
  };

  return (
    <>
      <Row className="mb-2">
        <Col style={{ textAlign: 'end' }}>
          <Form.Label column>Show</Form.Label>
        </Col>
        <Col xs={'auto'}>
          <Form.Select value={props.selectedOption} onChange={handleOptionChange}>
            <option>50</option>
            <option>100</option>
            <option>500</option>
            <option>1000</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Label column>rows</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              disabled={props.currentPage === 1}
              onClick={() => handlePageChange(props.currentPage - 1)}
            />
            {pageNumbers.map((page, index) => {
              if (page === null) {
                return <Pagination.Ellipsis key={index} disabled={true} />;
              }
              return (
                <Pagination.Item
                  key={index}
                  active={page === props.currentPage}
                  onClick={() => handlePageChange(page as number)}
                >
                  {page}
                </Pagination.Item>
              );
            })}
            <Pagination.Next
              disabled={props.currentPage === totalPages}
              onClick={() => handlePageChange(props.currentPage + 1)}
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
        </Col>
      </Row>
    </>
  );
};
