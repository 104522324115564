import { FC } from 'react';
import { Col } from 'react-bootstrap';
import { InfoFrame } from './InfoFrame';
import { MapInfoDeclaration } from '../../routes/MapInfo';
import MapComponent from './Map';

interface StationProps {
  id: string;
  mapInfo: MapInfoDeclaration;
}

export const Harvest: FC<StationProps> = (props: StationProps) => {
  return (
    <InfoFrame order={0} id={props.id} icon={props.mapInfo.icon} name={props.mapInfo.name}>
      <Col className="h-100">
        <MapComponent
          center={props.mapInfo.marker.position}
          zoom={16}
          markers={[props.mapInfo.marker]}
          mapTypeId={'satellite'}
        ></MapComponent>
      </Col>
    </InfoFrame>
  );
};
