import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { Button, Modal, Nav, Image } from 'react-bootstrap';
import { LogContext } from '../../context/LogContext';
import { StationInfo } from '../../routes/LogListInfo';
import logListInfoStyle from '../../styles/logListInfo.module.css';

interface Props {
  station: StationInfo;
  setShowImageModal: React.Dispatch<React.SetStateAction<boolean>>;
  showImageModal: boolean;
}

export const LogImageModal: FC<Props> = (props: Props) => {
  const logContext = useContext(LogContext);

  return (
    <Modal
      show={props.showImageModal}
      fullscreen={true}
      onHide={() => props.setShowImageModal(false)}
      centered
      style={{ textAlign: 'center', justifyContent: 'center' }}
    >
      <Modal.Header>
        <Nav.Link onClick={() => props.setShowImageModal(false)}>
          <FontAwesomeIcon className={logListInfoStyle.backIcon} icon={faCircleLeft} />
        </Nav.Link>
        <Modal.Title
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          Station {props.station.order} [{props.station.name}] <br /> Log
          {logContext.log?.id}
        </Modal.Title>
        <Button
          variant="secondary"
          size="lg"
          onClick={() => props.setShowImageModal(false)}
          className="position-absolute top-0 end-0 m-3"
        >
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Image
          fluid
          src={props.station.image_url.toString()}
          style={{
            textAlign: 'center',
            justifyContent: 'center'
          }}
        ></Image>
      </Modal.Body>
    </Modal>
  );
};
