import {
  faCog,
  faDolly,
  faFileInvoice,
  faMapLocationDot,
  faTableColumns,
  faTree,
  faUser,
  faUsers,
  faInfoCircle,
  faQuestion,
  faTriangleExclamation,
  faCircleLeft
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { InventoryHeader } from './Inventory/InventoryHeader';
import { LogContext } from '../context/LogContext';
import { useAuth } from '../hooks/AuthProvider';
import { QlikSelectionBar } from './QlikSelectionBar';

export const NavbarComponent = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('');
  const [pageIcon, setPageIcon] = useState(faQuestion);
  const [pageHeader, setPageHeader] = useState<JSX.Element | null>(null);
  const { user } = useAuth();

  const logContext = useContext(LogContext);

  const navigate = useNavigate();

  useEffect(() => {
    const start = `/${location.pathname.split('/')[1]}`;

    switch (start) {
      case '/inventory':
        setPageTitle('Inventory');
        setPageIcon(faDolly);
        setPageHeader(<InventoryHeader />);
        break;
      case '/loglist':
        if (
          location.pathname.match(
            /^\/loglist\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
          )
        ) {
          setPageTitle(`Log ID: ${logContext.log?.id}`);
          setPageIcon(faInfoCircle);
          setPageHeader(
            <Nav.Link onClick={() => navigate(-1)}>
              <FontAwesomeIcon className="fa-xl" icon={faCircleLeft} />
            </Nav.Link>
          );
        } else {
          setPageTitle('Log List');
          setPageIcon(faTree);
          setPageHeader(null);
        }
        break;
      case '/map':
        setPageTitle('Map');
        setPageIcon(faMapLocationDot);
        setPageHeader(
          <Nav.Link onClick={() => navigate(-1)}>
            <FontAwesomeIcon className="fa-xl" icon={faCircleLeft} />
          </Nav.Link>
        );
        break;
      case '/defects':
        setPageTitle('Defects');
        setPageIcon(faTriangleExclamation);
        setPageHeader(<QlikSelectionBar />);
        break;
      case '/reports':
        setPageTitle('Reports');
        setPageIcon(faFileInvoice);
        setPageHeader(null);
        break;
      case '/settings':
        setPageTitle('Settings');
        setPageIcon(faCog);
        setPageHeader(null);
        break;
      case '/users':
        setPageTitle('Users');
        setPageIcon(faUsers);
        setPageHeader(null);
        break;
      case '/dashboard':
      default:
        setPageTitle('Dashboard');
        setPageIcon(faTableColumns);
        setPageHeader(null);
        break;
    }
  }, [location]);

  return (
    <Navbar className="navbar-container" expand="lg" sticky="top">
      <Container fluid className="my-1">
        <Navbar.Brand className="d-none d-sm-block">
          <FontAwesomeIcon className="fa-xl" icon={pageIcon} /> {pageTitle}
        </Navbar.Brand>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>Filtering</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="w-100">{pageHeader ?? <></>}</Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Nav className={`ms-3 me-0 d-none ${pageHeader ? 'd-lg-block' : 'd-sm-block'}`}>
          {user?.name} <FontAwesomeIcon icon={faUser} className="fa-xl" />
        </Nav>
        {pageHeader ? (
          <Navbar.Toggle aria-controls={'offcanvasNavbar-expand'}>
            <FontAwesomeIcon icon={faCog}></FontAwesomeIcon> Filter
          </Navbar.Toggle>
        ) : (
          <></>
        )}
      </Container>
    </Navbar>
  );
};
