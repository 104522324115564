import { Nav } from 'react-bootstrap';
import { useInventoryTabs } from '../../context/InventoryTabContext';
import { QlikSelectionBar } from '../QlikSelectionBar';

export const InventoryHeader = () => {
  const { key, setKey } = useInventoryTabs();

  return (
    <Nav
      className="p-0"
      variant="pills"
      activeKey={key}
      onSelect={(k) => {
        if (k != null) setKey(k);
      }}
      style={{ width: '100%' }}
    >
      <Nav.Item className="px-1">
        <Nav.Link eventKey="inventory">Inventory</Nav.Link>
      </Nav.Item>
      <Nav.Item className="px-1">
        <Nav.Link eventKey="over-time">Over Time</Nav.Link>
      </Nav.Item>
      <Nav.Item className="px-1">
        <Nav.Link eventKey="turnover">Turnover</Nav.Link>
      </Nav.Item>
      <QlikSelectionBar />
    </Nav>
  );
};
