import {
  faCircleQuestion,
  faGears,
  faRuler,
  faTree,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { LogComment } from './components/LogInfo/LogComments';
import { LogListRow, Species } from './routes/LogList';
import { LogInfo, Stations } from './routes/LogListInfo';
import { format } from 'date-fns';
import { StationMarker } from './components/Map/Map';

export const getIcon = (station: Stations): IconDefinition => {
  switch (station) {
    case 'Harvesting':
      return faTree;
    case 'Measuring':
      return faRuler;
    case 'Sawline':
      return faGears;
    default:
      return faCircleQuestion;
  }
};

export const getBoundForMarkers = (markers: StationMarker[]) => {
  const bounds = new google.maps.LatLngBounds();

  markers.forEach((marker) => {
    bounds.extend(marker.position);
  });

  return bounds;
};

export const backend = () => {
  const endpoint = 'https://tracy.softwerk.se/demo/api';

  return {
    getLogInfo: async (logId: string, userToken: string): Promise<LogInfo> => {
      const requestOptions: RequestInit = {
        method: 'GET'
      };

      const response = await fetch(
        `${endpoint}/treeParts/${logId}/getLogInfo?access_token=${userToken}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();

        return {
          diameter: result.diameter,
          first_activity: new Date(result.first_activity),
          latest_activity: new Date(result.latest_activity),
          latest_station: result.latest_station,
          length: result.length,
          other_data: result.other_data,
          quality: result.quality,
          site: result.site,
          species: result.species,
          volume_toppmatt:
            result.volume_toppmatt != null
              ? parseFloat(result.volume_toppmatt)
              : result.volume_toppmatt,
          work_order: result.work_order,
          // TODO: Make safe
          stations: result.stations.map((station: unknown) => {
            if (typeof station === 'object' && station != null) {
              return {
                order: 'order' in station ? station.order : null,
                name: 'name' in station ? station.name : null,
                image_id: 'image_id' in station ? station.image_id : '',
                image_url: 'image_url' in station ? new URL(station.image_url as string) : null
              };
            }
          }),
          locations: result.locations.map((location: unknown): google.maps.LatLngLiteral | null => {
            if (typeof location === 'string') {
              const [lat, lng] = location.split(', ');
              return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
            return null;
          })
        };
      } else {
        const message = `Failed to fetch log Info: ${response.status} ${response.statusText}`;
        throw new Error(message);
      }
    },

    listLogs: async (
      site: string,
      orderBy: string,
      orderByDesc: boolean,
      searchComments: string,
      offset: number,
      limit: number,
      userId: string,
      userToken: string,
      filter: {
        species?: Species;
        start_date?: Date;
        end_date?: Date;
        min_quality?: number;
        max_quality?: number;
        min_diameter?: number;
        max_diameter?: number;
        min_length?: number;
        max_length?: number;
        min_stations?: number;
        max_stations?: number;
        min_compartment?: number;
        max_compartment?: number;
        work_order?: string;
      }
    ): Promise<{ totalRowsCount: number; rows: LogListRow[] }> => {
      const body: {
        site: string;

        order_by: string;
        order_by_desc: boolean;
        search: string;
        offset: number;
        limit: number;
        [key: string]: string | number | boolean;
      } = {
        site: site,
        order_by: orderBy,
        order_by_desc: orderByDesc,
        search: searchComments,
        offset: offset,
        limit: limit
      };

      for (const [key, value] of Object.entries(filter)) {
        if (value instanceof Date) {
          body[key] = format(value, 'yyyy-MM-dd HH:mm');
        } else if (typeof value === 'number' || typeof value === 'string') {
          body[key] = value;
        }
      }

      const headers = new Headers();
      headers.append('Authorization', userToken);
      headers.append('Content-Type', 'application/json');

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      };

      const response = await fetch(
        `${endpoint}/treeIdUsers/${userId}/listLogs?access_token=${userToken}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();

        return {
          totalRowsCount: result.total_rows_count,
          // TODO: Make safe
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          rows: result.rows.map((row: any): LogListRow => {
            return {
              stations: row.stations,
              latestStation: row.latest_station,
              latestActivity: new Date(row.latest_activity),
              firstActivity: new Date(row.first_activity),
              workOrder: row.work_order,
              lastComment: row.last_comment,
              diameter: row.diameter,
              tracyId: row.tracy_id
            };
          })
        };
      } else {
        const message = `Failed to fetch log list: ${response.status} ${response.statusText}`;
        throw new Error(message);
      }
    },

    addNewComment: async (logId: string, comment: string, userToken: string): Promise<boolean> => {
      const headers = new Headers();
      headers.append('Authorization', userToken);
      headers.append('Content-Type', 'application/json');

      const body = JSON.stringify({
        content: comment,
        log_id: logId
      });

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: body
      };

      const response = await fetch(
        `${endpoint}/comments/addNew?access_token=${userToken}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();

        if (!('content' in result) || !('logId' in result)) {
          const message = `Failed to add comment: ${response.status} ${response.statusText}`;
          throw new Error(message);
        }

        return true;
      } else {
        const message = `Failed to add comment: ${response.status} ${response.statusText}`;
        throw new Error(message);
      }
    },

    listComments: async (logId: string, userToken: string): Promise<LogComment[]> => {
      const requestOptions: RequestInit = {
        method: 'GET'
      };

      const response = await fetch(
        `${endpoint}/comments/list?access_token=${userToken}&log_id=${logId}&search=`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        // TODO: Make this safe
        return result;
      } else {
        const message = `Failed to fetch comments: ${response.status} ${response.statusText}`;
        throw new Error(message);
      }
    },

    logout: async (userToken: string): Promise<number> => {
      const requestOptions: RequestInit = {
        method: 'POST'
      };

      const response = await fetch(
        `${endpoint}/treeIdUsers/logout?access_token=${userToken}`,
        requestOptions
      );

      return response.status;
    },

    getQlikJwt: async (userId: string, userToken: string): Promise<string> => {
      const requestOptions: RequestInit = {
        method: 'GET'
      };

      const response = await fetch(
        `${endpoint}/treeIdUsers/${userId}/getQlikJwt?access_token=${userToken}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        return result.token;
      } else {
        const message = `Failed to fetch comments: ${response.status} ${response.statusText}`;
        throw new Error(message);
      }
    }
  };
};
