import { useEffect, useState } from 'react';
import { Button, Row, Col, Container, Card } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { Stations } from './LogListInfo';
import { backend } from '../utils';
import { useAuth } from '../hooks/AuthProvider';
import { LogListHeader } from '../components/LogList/Header';
import { LogListPagination } from '../components/LogList/Pagination';
import { LogListTable } from '../components/LogList/Table';

export type Species = 'Spruce' | 'Pine';

export interface LogListRow {
  stations: number;
  latestStation: Stations;
  latestActivity: Date;
  firstActivity: Date;
  workOrder: string;
  lastComment: string;
  diameter: number;
  tracyId: string;
}

export default function LogList() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [logData, setLogData] = useState<LogListRow[]>([]);
  const [clickedColumn, setClickedColumn] = useState<string>();
  const [sortColumnByDescending, setSortColumnByDescending] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();

  const urlSearchParamToNumber = (param: string): number | undefined => {
    const value = searchParams.get(param);
    if (value !== null) {
      const numberValue = parseInt(value);
      if (!isNaN(numberValue)) {
        return numberValue;
      }
    }
    return undefined;
  };

  const urlSearchParamToDate = (param: string): Date | undefined => {
    const value = searchParams.get(param);
    if (value !== null) {
      return new Date(value);
    }
    return undefined;
  };

  const urlSearchParamIsSpecies = (param: string): Species | undefined => {
    const value = searchParams.get(param);
    return ['Pine', 'Spruce'].includes(value as Species) ? (value as Species) : undefined;
  };

  const fetchLogList = async () => {
    if (user != null) {
      const logList = await backend().listLogs(
        'demo',
        clickedColumn ?? '',
        sortColumnByDescending,
        searchQuery ?? '',
        (currentPage - 1) * rowsPerPage,
        rowsPerPage,
        user.id,
        user.token,
        {
          species: urlSearchParamIsSpecies('species'),
          start_date: urlSearchParamToDate('start_date'),
          end_date: urlSearchParamToDate('end_date'),
          min_quality: urlSearchParamToNumber('min_quality'),
          max_quality: urlSearchParamToNumber('max_quality'),
          min_diameter: urlSearchParamToNumber('min_diameter'),
          max_diameter: urlSearchParamToNumber('max_diameter'),
          min_length: urlSearchParamToNumber('min_length'),
          max_length: urlSearchParamToNumber('max_length'),
          min_stations: urlSearchParamToNumber('min_stations'),
          max_stations: urlSearchParamToNumber('max_stations'),
          min_compartment: urlSearchParamToNumber('min_compartment'),
          max_compartment: urlSearchParamToNumber('max_compartment'),
          work_order: searchParams.get('work_order') ?? undefined
        }
      );
      setTotalRowsCount(logList.totalRowsCount);
      setLogData(logList.rows);
    }
  };

  useEffect(() => {
    fetchLogList();
  }, [
    currentPage,
    rowsPerPage,
    clickedColumn,
    totalRowsCount,
    sortColumnByDescending,
    searchParams
  ]);

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchLogList();
    }, 1000);

    return () => clearTimeout(getData);
  }, [searchQuery]);

  return (
    <Container fluid className="p-3 h-100 loglist-container">
      <Card className="h-100">
        <LogListHeader
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          setSearchQuery={setSearchQuery}
        ></LogListHeader>

        <LogListTable
          data={logData}
          setClickedColumn={setClickedColumn}
          setOrder={setSortColumnByDescending}
          order={sortColumnByDescending}
        ></LogListTable>

        <Row>
          <Col xs={0} lg={3}></Col>
          <Col xs={12} lg={6}>
            <LogListPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              selectedOption={rowsPerPage}
              setSelectedOption={setRowsPerPage}
              totalRowsCount={totalRowsCount}
            ></LogListPagination>
          </Col>
          <Col xs={12} lg={3} className={'d-flex justify-content-end align-items-end'}>
            <Button variant="secondary" className="m-2">
              Save
            </Button>
            <Button variant="secondary" className="me-2 my-2">
              Analyze
            </Button>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}
