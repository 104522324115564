import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTableColumns,
  faDolly,
  faFileInvoice,
  faMapLocationDot,
  faTree,
  faUsers,
  faRightFromBracket,
  faCog,
  faAngleLeft,
  faAngleRight,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons';
import Sidebar from 'react-bootstrap-sidebar-menu';
import logo from '../logo.png';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { backend } from '../utils';
import { useAuth } from '../hooks/AuthProvider';

export default function SidebarComponent() {
  const location = useLocation();
  const { user, logout } = useAuth();

  const [activeKey, setActiveKey] = useState(location.pathname);
  const [expanded, setExpanded] = useState(window.innerWidth >= 576 ? true : false);
  const [pageTitle, setPageTitle] = useState('');

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setActiveKey(eventKey);
    }
  };

  const handleToggle = (expanded: boolean) => {
    setExpanded(expanded);
  };

  useEffect(() => {
    const start = `/${location.pathname.split('/')[1]}`;

    setActiveKey(start);

    switch (start) {
      case '/inventory':
        setPageTitle('Inventory');
        break;
      case '/loglist':
        setPageTitle('Log List');
        break;
      case '/loglistinfo':
        setPageTitle('Log Info');
        break;
      case '/map':
        setPageTitle('Map');
        break;
      case '/defects':
        setPageTitle('Defects');
        break;
      case '/reports':
        setPageTitle('Reports');
        break;
      case '/settings':
        setPageTitle('Settings');
        break;
      case '/users':
        setPageTitle('Users');
        break;
      case '/dashboard':
        setPageTitle('Dashboard');
        break;
      default:
        setPageTitle('');
        break;
    }
  }, [location]);

  function handleLogout() {
    const logoutCall = async () => {
      if (user?.token) {
        const status = await backend().logout(user?.token);
        if (status === 204) {
          logout();
        }
      }
    };

    logoutCall();
  }

  return (
    <>
      {/* Sidebar for screens >= 576px */}
      <Sidebar
        className="sidebar-container d-none d-sm-block"
        bg="dark"
        variant="dark"
        expand="md"
        activeKey={activeKey}
        onSelect={handleSelect}
        onToggle={handleToggle}
        expanded={expanded}
      >
        <Sidebar.Collapse getScrollValue={200}>
          <Sidebar.Header>
            <Sidebar.Brand className="px-0 mx-0">
              <img src={logo} alt="Logo" className="d-inline-block align-top" /> {user?.company}
            </Sidebar.Brand>
            <Sidebar.Toggle>
              {expanded ? (
                <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
              )}
            </Sidebar.Toggle>
          </Sidebar.Header>
          <Sidebar.Body>
            <Sidebar.Nav>
              <Sidebar.Nav.Link eventKey="/dashboard" as="div" className="p-0 mb-1">
                <Link to="/dashboard" className="sidebar-menu-nav-link m-0">
                  <Sidebar.Nav.Icon>
                    <FontAwesomeIcon icon={faTableColumns} className="fa-xl" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Dashboard</Sidebar.Nav.Title>
                </Link>
              </Sidebar.Nav.Link>
              <Sidebar.Nav.Link eventKey="/inventory" as="div" className="p-0 mb-1">
                <Link to="/inventory" className="sidebar-menu-nav-link m-0">
                  <Sidebar.Nav.Icon>
                    <FontAwesomeIcon icon={faDolly} className="fa-xl" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Inventory</Sidebar.Nav.Title>
                </Link>
              </Sidebar.Nav.Link>
              <Sidebar.Nav.Link eventKey="/loglist" as="div" className="p-0 mb-1">
                <Link to="/loglist" className="sidebar-menu-nav-link m-0">
                  <Sidebar.Nav.Icon>
                    <FontAwesomeIcon icon={faTree} className="fa-xl" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Log List</Sidebar.Nav.Title>
                </Link>
              </Sidebar.Nav.Link>
              <Sidebar.Nav.Link eventKey="/map" as="div" className="p-0 mb-1">
                <Link to="/map" className="sidebar-menu-nav-link m-0">
                  <Sidebar.Nav.Icon>
                    <FontAwesomeIcon icon={faMapLocationDot} className="fa-xl" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Map</Sidebar.Nav.Title>
                </Link>
              </Sidebar.Nav.Link>
              <Sidebar.Nav.Link eventKey="/defects" as="div" className="p-0 mb-1">
                <Link to="/defects" className="sidebar-menu-nav-link m-0">
                  <Sidebar.Nav.Icon>
                    <FontAwesomeIcon icon={faTriangleExclamation} className="fa-xl" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Defects</Sidebar.Nav.Title>
                </Link>
              </Sidebar.Nav.Link>
              <hr className="p-0 mx-3 my-1"></hr>
              <Sidebar.Nav.Link eventKey="/reports" as="div" className="p-0 mb-1">
                <Link to="/reports" className="sidebar-menu-nav-link m-0">
                  <Sidebar.Nav.Icon>
                    <FontAwesomeIcon icon={faFileInvoice} className="fa-xl" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Reports</Sidebar.Nav.Title>
                </Link>
              </Sidebar.Nav.Link>
              <Sidebar.Nav.Link eventKey="/users" as="div" className="p-0 mb-1">
                <Link to="/users" className="sidebar-menu-nav-link m-0">
                  <Sidebar.Nav.Icon>
                    <FontAwesomeIcon icon={faUsers} className="fa-xl" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Users</Sidebar.Nav.Title>
                </Link>
              </Sidebar.Nav.Link>
              <Sidebar.Nav.Link eventKey="/settings" as="div" className="p-0 mb-1">
                <Link to="/settings" className="sidebar-menu-nav-link m-0">
                  <Sidebar.Nav.Icon>
                    <FontAwesomeIcon icon={faCog} className="fa-xl" />
                  </Sidebar.Nav.Icon>
                  <Sidebar.Nav.Title>Settings</Sidebar.Nav.Title>
                </Link>
              </Sidebar.Nav.Link>
              <hr className="p-0 mx-3 my-1"></hr>
              <Sidebar.Nav.Link onClick={handleLogout}>
                <Sidebar.Nav.Icon>
                  <FontAwesomeIcon icon={faRightFromBracket} className="fa-xl" />
                </Sidebar.Nav.Icon>
                <Sidebar.Nav.Title>Log Out</Sidebar.Nav.Title>
              </Sidebar.Nav.Link>
            </Sidebar.Nav>
          </Sidebar.Body>
        </Sidebar.Collapse>
      </Sidebar>
      {/* Top-bar for screens < 576px */}
      <Navbar
        className="sidebar-xs-container d-block d-sm-none"
        bg="dark"
        variant="dark"
        expand="lg"
        expanded={expanded}
        fixed="top"
      >
        <Container>
          <Navbar.Brand>
            <img src={logo} alt="Logo" className="d-inline-block align-top" /> {user?.company}
          </Navbar.Brand>
          <Navbar.Text>{pageTitle}</Navbar.Text>
          <Navbar.Toggle
            aria-controls="navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="me-auto">
              <Nav.Link eventKey="/dashboard" as="div" className="p-0 mb-0">
                <Link to="/dashboard" className="nav-link m-0" onClick={() => setExpanded(false)}>
                  Dashboard
                </Link>
              </Nav.Link>
              <Nav.Link eventKey="/inventory" as="div" className="p-0 mb-0">
                <Link to="/inventory" className="nav-link m-0" onClick={() => setExpanded(false)}>
                  Inventory
                </Link>
              </Nav.Link>
              <Nav.Link eventKey="/loglist" as="div" className="p-0 mb-0">
                <Link to="/loglist" className="nav-link m-0" onClick={() => setExpanded(false)}>
                  Log List
                </Link>
              </Nav.Link>
              <Nav.Link eventKey="/reports" as="div" className="p-0 mb-0">
                <Link to="/reports" className="nav-link m-0" onClick={() => setExpanded(false)}>
                  Reports
                </Link>
              </Nav.Link>
              <Nav.Link eventKey="/map" as="div" className="p-0 mb-0">
                <Link to="/map" className="nav-link m-0" onClick={() => setExpanded(false)}>
                  Map
                </Link>
              </Nav.Link>
              <Nav.Link eventKey="/users" as="div" className="p-0 mb-0">
                <Link to="/users" className="nav-link m-0" onClick={() => setExpanded(false)}>
                  Users
                </Link>
              </Nav.Link>
              <Nav.Link eventKey="/settings" as="div" className="p-0 mb-0">
                <Link to="/settings" className="nav-link m-0" onClick={() => setExpanded(false)}>
                  Settings
                </Link>
              </Nav.Link>
              <Nav.Link className="mb-0 nav-link" onClick={handleLogout}>
                {`Log Out (${user?.name})`}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
