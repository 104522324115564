import { useEffect, useRef } from 'react';
import { Nav } from 'react-bootstrap';
import { useQlik } from '../hooks/QlikProvider';

export const QlikSelectionBar = () => {
  const selectionsRef = useRef<HTMLDivElement>(null);
  const qlik = useQlik();

  const unmount = async () => {
    if (qlik.nebula != null) {
      const selections = await qlik.nebula.selections();
      selections.unmount();
    }
  };

  const mount = async () => {
    if (qlik.nebula != null) {
      const selections = await qlik.nebula.selections();
      if (selectionsRef.current != null) {
        selections.mount(selectionsRef.current);
      }
    }
  };

  useEffect(() => {
    mount();

    return () => {
      unmount();
    };
  }, [qlik]);

  return <Nav.Item className="d-flex px-1" style={{ flex: 1 }} ref={selectionsRef} />;
};
