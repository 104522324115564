import { Component } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import footerStyle from '../styles/footer.module.css';

class FooterComponent extends Component {
  render() {
    return (
      <Navbar className={`${footerStyle.footer} py-3`} collapseOnSelect expand="lg" fixed="bottom">
        <Container fluid>
          <Nav.Item className="d-flex align-items-center">
            <Navbar.Text className={`${footerStyle.copyright} mr-auto`}>
              &copy; Copyright {new Date().getFullYear()} - Tracy of Sweden
            </Navbar.Text>
          </Nav.Item>

          <Nav.Item className="d-flex align-items-center">
            <Nav.Link
              href="https://tracyofsweden.com/en/startpage/"
              eventKey="link"
              className={`${footerStyle.tracyLink}`}
            >
              Visit Tracy of Sweden Website{' '}
            </Nav.Link>
            <Nav.Link href="https://www.facebook.com/tracyofsweden/">
              <FontAwesomeIcon className={footerStyle.fbIcon} icon={faFacebook} />
            </Nav.Link>
          </Nav.Item>
        </Container>
      </Navbar>
    );
  }
}

export default FooterComponent;
