import { Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import Main from './components/Main';
import { NavbarComponent } from './components/Navbar';
import SidebarComponent from './components/Sidebar';
import './styles/_styles.scss';

export default function App() {
  return (
    <Layout>
      <SidebarComponent />
      <NavbarComponent />
      <Main>
        <Outlet />
      </Main>
    </Layout>
  );
}
