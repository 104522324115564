import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LogContext } from '../../context/LogContext';
import { Stations } from '../../routes/LogListInfo';
import logListStyle from '../../styles/logList.module.css';
import format from 'date-fns/format';
import { LogListStationBars } from './StationBar';

export interface LogListRow {
  stations: number;
  latestStation: Stations;
  latestActivity: Date;
  firstActivity: Date;
  workOrder: string;
  lastComment: string;
  diameter: number;
  tracyId: string;
}

interface Props {
  data: LogListRow[];
  setClickedColumn: React.Dispatch<React.SetStateAction<string | undefined>>;
  order: boolean;
  setOrder: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LogListTable: FC<Props> = (props: Props) => {
  /* getting the data */
  const columns = new Map([
    ['stations', 'Station Tracking'],
    ['latest_activity', 'Latest Activity'],
    ['first_activity', 'First Activity'],
    ['work_order', 'Work Order'],
    ['last_comment', 'Latest Comments'],
    ['diameter', 'Diameter (mm)'],
    ['tracy_id', 'TracyId']
  ]);

  const columnsKeys = Array.from(columns.keys());

  /* sorting the table */

  const handleSorting = (key: string) => {
    props.setClickedColumn(key);
    props.setOrder(!props.order);
  };

  /* row click */
  const navigate = useNavigate();
  const logContext = useContext(LogContext);

  const handleRowClick = (logId: string) => {
    logContext.setLog({
      // TODO: This is broken and needs to use a reducer
      id: logId,
      url: ''
    });
    navigate(`/loglist/${logId}`);
  };

  return (
    <Container style={{ overflow: 'auto' }} fluid className="mb-2">
      <Table hover className={`${logListStyle.table}`}>
        <thead className={`sticky-top ${logListStyle.tableHeader}`}>
          <tr>
            {columnsKeys.map((key, index) => (
              <th
                key={key}
                onClick={() => handleSorting(key)}
                className={
                  index === 0
                    ? logListStyle.roundedTopLeft
                    : index === columnsKeys.length - 1
                    ? logListStyle.roundedTopRight
                    : ''
                }
                style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
              >
                {columns.get(key)} <FontAwesomeIcon icon={faSort} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((d) => (
            <tr
              key={d.tracyId}
              onClick={() => handleRowClick(d.tracyId)}
              style={{ cursor: 'pointer' }}
            >
              <td>
                <LogListStationBars stations={d.stations}></LogListStationBars>
              </td>
              <td>{`${format(d.latestActivity, 'dd MMM yyyy, HH:mm:ss')} [${d.latestStation}]`}</td>
              <td>{`${format(d.firstActivity, 'dd MMM yyyy, HH:mm:ss')} [Measuring]`}</td>
              <td>{d.workOrder != null ? <>{d.workOrder}</> : <>{'unavailable'}</>}</td>
              <td
                style={{
                  maxWidth: '6rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {d.lastComment != null ? d.lastComment : 'no comments'}
              </td>
              <td>{d.diameter != null ? <>{d.diameter}</> : <>{'unavailable'}</>}</td>
              <td>{d.tracyId}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
