import { Row, Col, Card } from 'react-bootstrap';
import logListInfoStyle from '../../styles/logListInfo.module.css';
import { LogInfo } from '../../routes/LogListInfo';

export default function LogOtherData({ logData }: { logData?: LogInfo }) {
  return (
    <>
      <Row>
        <Col>
          <Card body style={{ backgroundColor: '#f6f6f6', border: 'none' }}>
            <Row>
              <Card.Title>Other Data</Card.Title>
            </Row>
            <br></br>
            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
              <Card
                className={`${logListInfoStyle.data} text-center`}
                style={{ height: '325px', border: 'none', overflow: 'auto' }}
                body
              >
                {logData != null ? (
                  <>
                    {Object.entries(logData.other_data).map(([key, value]) => (
                      <div
                        key={key}
                        className={`${logListInfoStyle.otherData} text-center`}
                        style={{
                          display: 'inline-block',
                          width: '100%',
                          maxWidth: '350px',
                          margin: '0 auto'
                        }}
                      >
                        <span>{key}:</span> <span style={{ color: 'gray' }}>{value}</span>
                        {/*{index % 3 === 2 && <br />}*/}{' '}
                        {/* add line break for every third item */}
                      </div>
                    ))}
                  </>
                ) : (
                  <> {'LOADING...'}</>
                )}
              </Card>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
