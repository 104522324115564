import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './routes/Login';
import Defects from './routes/Defects';
import LogList from './routes/LogList';
import NotFound from './routes/NotFound';
import Dashboard from './routes/Dashboard';
import Inventory from './routes/Inventory';
import Map from './routes/Map';
import Reports from './routes/Reports';
import Settings from './routes/Settings';
import Users from './routes/Users';
import Logout from './routes/Logout';
import LogListInfo from './routes/LogListInfo';
import { LogContextProvider } from './context/LogContext';
import { MapInfo } from './routes/MapInfo';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AuthProvider } from './hooks/AuthProvider';
import { QlikProvider } from './hooks/QlikProvider';
import { InventoryTabs } from './context/InventoryTabContext';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <QlikProvider>
            <App />
          </QlikProvider>
        </ProtectedRoute>
      </AuthProvider>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: '/inventory',
        element: <Inventory />
      },
      {
        path: '/loglist',
        element: <LogList />
      },
      {
        path: '/map',
        element: <Map />
      },
      {
        path: '/defects',
        element: <Defects />
      },
      {
        path: '/reports',
        element: <Reports />
      },
      {
        path: '/settings',
        element: <Settings />
      },
      {
        path: '/users',
        element: <Users />
      },
      {
        path: '/loglist/:id',
        element: <LogListInfo />
      },
      {
        path: '/loglist/:id/map/:index',
        element: <MapInfo />
      }
    ]
  },
  {
    path: '/login',
    element: (
      <AuthProvider>
        <Login />
      </AuthProvider>
    )
  },
  {
    path: '/logout',
    element: <Logout />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <style>
      @import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap); @import
      url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
    </style>
    <LogContextProvider>
      <InventoryTabs>
        <RouterProvider router={router} />
      </InventoryTabs>
    </LogContextProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
