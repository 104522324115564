import { createContext, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

interface Props {
  children: JSX.Element;
}

interface AuthUser {
  name: string;
  token: string;
  id: string;
  loggedIn: boolean;
  company: string;
}

const AuthContext = createContext<{
  user: AuthUser | null;
  login: (data: AuthUser) => void;
  logout: () => void;
}>({ user: null, login: () => null, logout: () => null });

export const AuthProvider = (props: Props) => {
  const [user, setUser] = useLocalStorage<AuthUser | null>('user', null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if ((location.pathname === '/login' || location.pathname === '/') && user) {
      navigate('/loglist');
    }
  }, [user]);

  const login = (data: AuthUser) => {
    setUser(data);
  };

  const logout = () => {
    setUser(null);
    navigate('/logout', { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
