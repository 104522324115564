import { Container } from 'react-bootstrap';
import logo from '../images/tracy.png';

export default function NotFound() {
  return (
    <Container fluid className="text-center pt-5" style={{ minWidth: '280px' }}>
      <img src={logo} alt="Logo" style={{ minWidth: '250px', maxWidth: '1000px', width: '75%' }} />
      <p>This page could not be found.</p>
    </Container>
  );
}
