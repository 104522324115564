import { Row, Col, Card, Overlay, Tooltip } from 'react-bootstrap';
import logListInfoStyle from '../../styles/logListInfo.module.css';
import { LogInfo } from '../../routes/LogListInfo';
import { useRef, useState } from 'react';
import format from 'date-fns/format';

export default function InfoLog({ logData }: { logData?: LogInfo }) {
  const [hovered, setHovered] = useState(false);
  const target = useRef(null);

  return (
    <Row>
      <Col xs={12} xl={6} className="mb-3 mb-xl-0">
        <Card body className={`${logListInfoStyle.container}`}>
          <Row>
            <Col className={`${logListInfoStyle.subtitle} text-center`}>
              Diameter
              <Row className={`${logListInfoStyle.data} `}>
                {logData?.diameter != null ? <>{logData?.diameter} mm</> : <>{'unavailable'}</>}
              </Row>
            </Col>
            <Col className={`${logListInfoStyle.subtitle} text-center`}>
              Length
              <Row className={`${logListInfoStyle.data} `}>
                {logData?.length != null ? <>{logData?.length} cm</> : <>{'unavailable'}</>}
              </Row>
            </Col>
            <Col className={`${logListInfoStyle.subtitle} text-center`}>
              Volume
              <Row className={`${logListInfoStyle.data} `}>
                {logData?.volume_toppmatt != null ? (
                  <>{logData?.volume_toppmatt} m³ </>
                ) : (
                  <>{'unavailable'}</>
                )}
              </Row>
            </Col>
            <Col className={`${logListInfoStyle.subtitle} text-center`}>
              Species
              <Row className={`${logListInfoStyle.data} `}>
                {logData?.species != null ? <>{logData?.species}</> : <>{'unavailable'}</>}
              </Row>
            </Col>
            <Col className={`${logListInfoStyle.subtitle} text-center`}>
              Quality
              <Row
                ref={target}
                className={`${logListInfoStyle.data}`}
                onMouseOver={() => setHovered(true)}
                onMouseOut={() => setHovered(false)}
              >
                {logData?.quality != null ? <>{logData?.quality}</> : <>{'unavailable'}</>}
                {hovered && (
                  <Overlay target={target.current} show={hovered} placement="right">
                    {(props) => (
                      <Tooltip id="overlay-example" {...props}>
                        Here you will find details about the quality
                      </Tooltip>
                    )}
                  </Overlay>
                )}
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={12} xl={6}>
        <Card className={`${logListInfoStyle.container} text-center`} body>
          <Row>
            <Col className={`${logListInfoStyle.subtitle} text-center`}>
              Latest Activity
              {logData?.latest_activity && (
                <Row className={`${logListInfoStyle.data} `}>
                  {format(new Date(logData.latest_activity), 'dd MMM yyyy, HH:mm:ss')}
                </Row>
              )}
            </Col>
            <Col className={`${logListInfoStyle.subtitle} text-center`}>
              First Activity
              {logData?.first_activity && (
                <Row className={`${logListInfoStyle.data} `}>
                  {format(new Date(logData.first_activity), 'dd MMM yyyy, HH:mm:ss')}
                </Row>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
