import { useEffect, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import logListInfoStyle from '../styles/logListInfo.module.css';
import MapComponent, { StationMarker } from '../components/Map/Map';
import { Species } from './LogList';
import { backend } from '../utils';
import InfoLogComponent from '../components/LogInfo/InfoLog';
import LogOtherDataComponent from '../components/LogInfo/LogOtherData';
import LogCommentsComponent from '../components/LogInfo/LogComments';
import LogImagesComponent from '../components/LogInfo/LogImages';
import { useAuth } from '../hooks/AuthProvider';

export type Stations = 'Harvesting' | 'Measuring' | 'Sawline';

export interface StationInfo {
  order: number;
  name: Stations;
  image_id: string;
  image_url: URL;
}
export interface LogInfo {
  diameter: number;
  first_activity: Date;
  latest_activity: Date;
  latest_station: Stations;
  length: number;
  other_data: object;
  quality: number;
  site: string;
  species: Species;
  stations: StationInfo[];
  volume_toppmatt: number;
  work_order: string;
  locations: google.maps.LatLngLiteral[];
}

export default function LogListInfo() {
  const [logData, setLogData] = useState<LogInfo>();
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchLogInfo = async () => {
    if (user?.token && id) {
      const logInfo = await backend().getLogInfo(id, user.token);
      setLogData(logInfo);
    }
  };

  useEffect(() => {
    fetchLogInfo();
  }, []);

  if (logData) {
    return (
      <>
        <Container fluid className={`${logListInfoStyle.container}`}>
          <Card body>
            <InfoLogComponent logData={logData}></InfoLogComponent>
            <br></br>
            <Row>
              <Col md={true}>
                <LogImagesComponent
                  stations={logData.stations}
                  firstActivity={logData.first_activity}
                  latestActivity={logData.latest_activity}
                ></LogImagesComponent>

                <br></br>

                <Row>
                  <Col xs={12} md={6} lg={6} xl={6}>
                    <Card body style={{ backgroundColor: '#f6f6f6', border: 'none' }}>
                      <LogCommentsComponent />
                    </Card>
                  </Col>

                  <Col xs={12} md={6} lg={6} xl={6}>
                    <Card
                      className={`text-center`}
                      style={{
                        marginTop: '-12px',
                        marginBottom: '8px',
                        height: '430px',
                        border: 'none'
                      }}
                      body
                    >
                      <MapComponent
                        fitBounds={true}
                        markers={logData?.locations.map((location, index): StationMarker => {
                          const station = logData?.stations.find(
                            (station) => station.order === index
                          );
                          return {
                            log_id: id,
                            order_id: index,
                            position: location,
                            type: station ? station.name : 'Harvesting',
                            onClick: () => navigate(`/loglist/${id}/map/${index}`)
                          };
                        })}
                      ></MapComponent>
                    </Card>
                  </Col>
                </Row>

                <LogOtherDataComponent logData={logData}></LogOtherDataComponent>
              </Col>
            </Row>
          </Card>
        </Container>
      </>
    );
  }

  return <></>;
}
