import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

interface Props {
  children: JSX.Element;
}

interface InventoryTabs {
  key: string;
  setKey: Dispatch<SetStateAction<string>>;
}

const InventoryTabsContext = createContext<InventoryTabs>({
  key: 'inventory',
  setKey: () => null
});

export const InventoryTabs = (props: Props) => {
  const [key, setKey] = useState('inventory');

  return (
    <InventoryTabsContext.Provider value={{ key, setKey }}>
      {props.children}
    </InventoryTabsContext.Provider>
  );
};

export const useInventoryTabs = () => {
  return useContext(InventoryTabsContext);
};
