import { FC } from 'react';
import { Col, Image } from 'react-bootstrap';
import { MapInfoDeclaration } from '../../routes/MapInfo';
import { InfoFrame } from './InfoFrame';
import MapComponent from './Map';

interface StationProps {
  id: string;
  order: number;
  mapInfo: MapInfoDeclaration;
}

export const Station: FC<StationProps> = (props: StationProps) => {
  return (
    <InfoFrame
      order={props.order}
      id={props.id}
      icon={props.mapInfo.icon}
      name={props.mapInfo.name}
    >
      <Col className="h-100">
        <MapComponent
          center={props.mapInfo.marker.position}
          zoom={16}
          markers={[props.mapInfo.marker]}
          mapTypeId={'satellite'}
        ></MapComponent>
      </Col>
      <Col lg={4} className="d-flex justify-content-center">
        <Image
          className="align-middle"
          src={props.mapInfo.image_url?.toString()}
          alt="Log"
          fluid
        ></Image>
      </Col>
    </InfoFrame>
  );
};
