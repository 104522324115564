import { faClockRotateLeft, faSliders, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthProvider';
import logListStyle from '../../styles/logList.module.css';
import FilterModal, { LogListFilterValues } from './LogListFilterModal';

interface Props {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit) | undefined,
    navigateOpts?: NavigateOptions | undefined
  ) => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const LogListHeader: FC<Props> = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { user } = useAuth();

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleSubmitModal = (_filter: LogListFilterValues) => {
    props.setSearchParams((prevSearchParams) => {
      for (const [key, value] of Object.entries(_filter)) {
        if (value !== undefined) {
          prevSearchParams.set(key, value);
        } else {
          prevSearchParams.delete(key);
        }
      }
      return prevSearchParams;
    });
  };

  /* filter comments */
  const filterComments = (searchQuery: string) => {
    props.setSearchQuery(searchQuery);
  };

  return (
    <Row className="m-3 loglist-header">
      <Col className="d-none d-lg-block">
        <h4>{user?.company}</h4>
      </Col>
      <Col xs="auto" className={`${logListStyle.appliedFilters}`}>
        {Array.from(props.searchParams.entries()).map(([key, value]) => (
          <Col key={key}>
            {key}: {value}
          </Col>
        ))}
      </Col>
      <Col xs="auto" className="px-0">
        <Button className="skeleton-button">
          <FontAwesomeIcon icon={faClockRotateLeft} className="fa-xl" />
        </Button>
      </Col>
      <Col xs="auto" className="px-0">
        <Button className="skeleton-button">
          <FontAwesomeIcon icon={faSliders} onClick={handleOpenModal} className="fa-xl" />
        </Button>
      </Col>
      <FilterModal
        show={showModal}
        initialState={props.searchParams}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
      />
      <Col xs="auto" className="px-1">
        <InputGroup className={logListStyle.inputGroup}>
          <InputGroup.Text>
            <FontAwesomeIcon className={logListStyle.search} icon={faSearch} />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Search..."
            onChange={(e) => filterComments(e.target.value)}
          />
        </InputGroup>
      </Col>
    </Row>
  );
};
