import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

type LogInfo = {
  id: string;
  url: string;
};

export type LogContextType = {
  log: LogInfo | null;
  setLog: Dispatch<SetStateAction<LogInfo | null>>;
};

type LogContextProviderType = {
  children: ReactNode;
};

export const LogContext = createContext({} as LogContextType);

export const LogContextProvider = ({ children }: LogContextProviderType) => {
  const [log, setLog] = useState<LogInfo | null>(null);
  return <LogContext.Provider value={{ log, setLog }}>{children}</LogContext.Provider>;
};
