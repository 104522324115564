import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Row, Tab } from 'react-bootstrap';
import GraphComponent from '../components/Inventory/Graph';
import { useInventoryTabs } from '../context/InventoryTabContext';
import { useQlik } from '../hooks/QlikProvider';
import QlikConfig from '../qlik.config.dev.json';

export default function Inventory() {
  const qlik = useQlik();
  const { key } = useInventoryTabs();
  const [diameterGraph, setDiameterGraph] = useState(true);
  const [lengthGraph, setlengthGraph] = useState(true);

  const numberOfLogsPerDiameterRef = useRef<HTMLDivElement>(null);
  const numberOfLogsPerDiameterAltRef = useRef<HTMLDivElement>(null);
  const numberOfLogsPerLengthRef = useRef<HTMLDivElement>(null);
  const numberOfLogsPerLengthAltRef = useRef<HTMLDivElement>(null);
  const numberOfLogsPerCompartmentRef = useRef<HTMLDivElement>(null);
  const numberOfLogsOverQualityRef = useRef<HTMLDivElement>(null);
  const numberOfLogsWorkOrderRef = useRef<HTMLDivElement>(null);
  const totalLogsRef = useRef<HTMLDivElement>(null);
  const totalVolumeRef = useRef<HTMLDivElement>(null);
  const dates = useRef<HTMLDivElement>(null);

  const numberOfLogsPerDiameterOverTimeRef = useRef<HTMLDivElement>(null);
  const numberOfLogsPerLengthOverTimeRef = useRef<HTMLDivElement>(null);
  const numberOfLogsPerCompartmentOverTimeRef = useRef<HTMLDivElement>(null);
  const numberOfLogsPerWorkOrderOverTimeRef = useRef<HTMLDivElement>(null);

  const ageRef = useRef<HTMLDivElement>(null);
  const averageTurnover = useRef<HTMLDivElement>(null);
  const treeList = useRef<HTMLDivElement>(null);

  const render = async () => {
    if (qlik.nebula != null) {
      if (dates.current != null) {
        qlik.nebula.render({
          element: dates.current,
          id: QlikConfig.dates
        });
      }
      if (numberOfLogsPerDiameterRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerDiameterRef.current,
          id: QlikConfig.numberOfLogsPerDiameter
        });
      }
      if (numberOfLogsPerDiameterAltRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerDiameterAltRef.current,
          id: QlikConfig.numberOfLogsPerDiameterAlt
        });
      }
      if (numberOfLogsPerLengthRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerLengthRef.current,
          id: QlikConfig.numberOfLogsPerLengthSegment
        });
      }
      if (numberOfLogsPerLengthAltRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerLengthAltRef.current,
          id: QlikConfig.numberOfLogsPerLengthSegmentAlt
        });
      }
      if (numberOfLogsPerCompartmentRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerCompartmentRef.current,
          id: QlikConfig.numberOfLogsPerCompartment
        });
      }
      if (numberOfLogsOverQualityRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsOverQualityRef.current,
          id: QlikConfig.numberOfLogsInInventoryOverQuality
        });
      }
      if (totalLogsRef.current != null) {
        qlik.nebula.render({
          element: totalLogsRef.current,
          id: QlikConfig.totalLogsInStock
        });
      }
      if (totalVolumeRef.current != null) {
        qlik.nebula.render({
          element: totalVolumeRef.current,
          id: QlikConfig.totalVolumeInStock
        });
      }
      if (numberOfLogsWorkOrderRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsWorkOrderRef.current,
          id: QlikConfig.numberOfLogsPerWorkOrderPerQualityClass
        });
      }

      if (numberOfLogsPerDiameterOverTimeRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerDiameterOverTimeRef.current,
          id: QlikConfig.numberOfLogsPerDiameterOverTime
        });
      }
      if (numberOfLogsPerLengthOverTimeRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerLengthOverTimeRef.current,
          id: QlikConfig.numberOfLogsPerLengthOverTime
        });
      }
      if (numberOfLogsPerCompartmentOverTimeRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerCompartmentOverTimeRef.current,
          id: QlikConfig.numberOfLogsPerCompartmentOverTime
        });
      }
      if (numberOfLogsPerWorkOrderOverTimeRef.current != null) {
        qlik.nebula.render({
          element: numberOfLogsPerWorkOrderOverTimeRef.current,
          id: QlikConfig.numberOfLogsPerWorkOrderOverTime
        });
      }

      if (ageRef.current != null) {
        qlik.nebula.render({
          element: ageRef.current,
          id: QlikConfig.age
        });
      }
      if (averageTurnover.current != null) {
        qlik.nebula.render({
          element: averageTurnover.current,
          id: QlikConfig.averageTurnover
        });
      }
      if (treeList.current != null) {
        qlik.nebula.render({
          element: treeList.current,
          id: QlikConfig.treeList
        });
      }
    }
  };

  useEffect(() => {
    render();
  }, [qlik]);

  return (
    <Container fluid className="p-3">
      <Tab.Container id="left-tabs-example" activeKey={key}>
        <Row>
          <Tab.Content>
            <Tab.Pane eventKey="inventory" title="Inventory">
              <Card style={{ backgroundColor: '#ffffff00', border: 0 }}>
                <Card.Body>
                  <Row>
                    <Col xs={12} xl={6}>
                      <Container
                        fluid
                        ref={totalLogsRef}
                        style={{ width: '100%', height: '5rem' }}
                      />
                    </Col>
                    <Col xs={12} xl={6}>
                      <Container
                        fluid
                        ref={totalVolumeRef}
                        style={{ width: '100%', height: '5rem' }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row className="g-2">
                <Col xs={12} xl={6}>
                  <GraphComponent>
                    <>
                      <Button
                        style={{ position: 'absolute', zIndex: 1000, left: '16px' }}
                        onClick={() => {
                          setDiameterGraph(!diameterGraph);
                        }}
                      >
                        <FontAwesomeIcon icon={faRotate}></FontAwesomeIcon>
                      </Button>
                      <Container
                        hidden={diameterGraph}
                        fluid
                        ref={numberOfLogsPerDiameterRef}
                        className="w-100 h-100"
                      />
                      <Container
                        hidden={!diameterGraph}
                        fluid
                        ref={numberOfLogsPerDiameterAltRef}
                        className="w-100 h-100"
                      />
                    </>
                  </GraphComponent>
                </Col>
                <Col xs={12} xl={6}>
                  <GraphComponent>
                    <>
                      <Button
                        style={{ position: 'absolute', zIndex: 1000, left: '16px' }}
                        onClick={() => {
                          setlengthGraph(!lengthGraph);
                        }}
                      >
                        <FontAwesomeIcon icon={faRotate}></FontAwesomeIcon>
                      </Button>
                      <Container
                        hidden={lengthGraph}
                        fluid
                        ref={numberOfLogsPerLengthRef}
                        className="w-100 h-100"
                      />
                      <Container
                        hidden={!lengthGraph}
                        fluid
                        ref={numberOfLogsPerLengthAltRef}
                        className="w-100 h-100"
                      />
                    </>
                  </GraphComponent>
                </Col>
                <Col xs={12} xl={6}>
                  <GraphComponent>
                    <Container fluid ref={numberOfLogsPerCompartmentRef} className="w-100 h-100" />
                  </GraphComponent>
                </Col>
                <Col xs={12} xl={6}>
                  <GraphComponent>
                    <Container fluid ref={numberOfLogsOverQualityRef} className="w-100 h-100" />
                  </GraphComponent>
                </Col>
                <Col xs={12}>
                  <GraphComponent>
                    <Container fluid ref={numberOfLogsWorkOrderRef} className="w-100 h-100" />
                  </GraphComponent>
                </Col>
                <Col xs={12}>
                  <GraphComponent>
                    <Container fluid ref={dates} className="w-100 h-100" />
                  </GraphComponent>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="over-time">
              <Row className="g-2">
                <Col xs={12} xl={6}>
                  <GraphComponent>
                    <Container
                      fluid
                      ref={numberOfLogsPerDiameterOverTimeRef}
                      className="w-100 h-100"
                    />
                  </GraphComponent>
                </Col>
                <Col xs={12} xl={6}>
                  <GraphComponent>
                    <Container
                      fluid
                      ref={numberOfLogsPerLengthOverTimeRef}
                      className="w-100 h-100"
                    />
                  </GraphComponent>
                </Col>
                <Col xs={12} xl={6}>
                  <GraphComponent>
                    <Container
                      fluid
                      ref={numberOfLogsPerCompartmentOverTimeRef}
                      className="w-100 h-100"
                    />
                  </GraphComponent>
                </Col>
                <Col xs={12} xl={6}>
                  <GraphComponent>
                    <Container
                      fluid
                      ref={numberOfLogsPerWorkOrderOverTimeRef}
                      className="w-100 h-100"
                    />
                  </GraphComponent>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="turnover">
              <Row className="g-2">
                <Col xs={12} lg={9}>
                  <GraphComponent>
                    <Container fluid ref={ageRef} className="w-100 h-100" />
                  </GraphComponent>
                </Col>
                <Col xs={12} lg={3}>
                  <GraphComponent>
                    <Container fluid ref={averageTurnover} className="w-100 h-100" />
                  </GraphComponent>
                </Col>
                <Col xs={12}>
                  <GraphComponent>
                    <Container fluid ref={treeList} className="w-100 h-100" />
                  </GraphComponent>
                </Col>
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Row>
      </Tab.Container>
    </Container>
  );
}
