import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Harvest } from '../components/Map/Harvest';
import { StationMarker } from '../components/Map/Map';
import { Station } from '../components/Map/Station';
import { useAuth } from '../hooks/AuthProvider';
import { backend, getIcon } from '../utils';
import { Stations } from './LogListInfo';

export interface MapInfoDeclaration {
  name: Stations;
  icon: IconDefinition;
  image_url: URL | null;
  marker: StationMarker;
}

export const MapInfo = () => {
  const { id, index } = useParams();
  const { user } = useAuth();

  const [mapInfo, setMapInfo] = useState<MapInfoDeclaration>();

  useEffect(() => {
    if (id != null && index != null) {
      const fetchLogInfo = async () => {
        if (user?.token) {
          const logInfo = await backend().getLogInfo(id, user.token);

          const station = logInfo.stations.find((station) => station.order === parseInt(index));

          if (station !== undefined) {
            setMapInfo({
              name: station.name,
              image_url: station.image_url,
              marker: {
                position: logInfo.locations[parseInt(index)],
                type: station.name
              },
              icon: getIcon(station.name)
            });
          } else {
            setMapInfo({
              name: 'Harvesting',
              image_url: null,
              marker: {
                position: logInfo.locations[parseInt(index)],
                type: 'Harvesting'
              },
              icon: getIcon('Harvesting')
            });
          }
        }
      };

      fetchLogInfo();
    }
  }, []);

  if (id !== undefined && index !== undefined && mapInfo !== undefined) {
    if (index === '0') {
      return <Harvest id={id} mapInfo={mapInfo}></Harvest>;
    } else {
      return <Station id={id} order={parseInt(index)} mapInfo={mapInfo}></Station>;
    }
  } else {
    return <></>;
  }
};
