import { useState } from 'react';
import { Card, Row, Button, Col } from 'react-bootstrap';
import CompareModal from './CompareModal';
import logListInfoStyle from '../../styles/logListInfo.module.css';
import { LogImageCard } from './LogImageCard';
import { StationInfo } from '../../routes/LogListInfo';

interface Props {
  stations: StationInfo[];
  firstActivity: Date;
  latestActivity: Date;
}

export default function LogImages(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const handleCompare = () => {
    setShowModal(true);
  };

  return (
    <Card className={`${logListInfoStyle.container} text-center`} body>
      <Row className="mb-2">
        {props.stations.map((station) => {
          return (
            <Col key={station.order} xs={12} md={6} lg={4} xl={3}>
              <LogImageCard station={station} date={props.firstActivity}></LogImageCard>
            </Col>
          );
        })}
      </Row>
      <Row className="justify-content-center">
        <Button variant="secondary" style={{ maxWidth: '150px' }} onClick={handleCompare}>
          Compare
        </Button>
      </Row>
      <CompareModal fullscreen={true} show={showModal} onHide={() => setShowModal(false)} />
    </Card>
  );
}
