import { useNavigate } from 'react-router-dom';
import MapComponent, { StationMarker } from '../components/Map/Map';
import coordinates from '../static/coordinates/frödinge.json';
import { Stations } from './LogListInfo';

export default function Map() {
  const navigate = useNavigate();

  const stations: StationMarker[] = Object.entries(coordinates).map((coordinate): StationMarker => {
    const workOrder = parseInt(coordinate[0]);
    return {
      position: coordinate[1],
      type: isNaN(workOrder) ? (coordinate[0] as Stations) : 'Harvesting',
      workorder: workOrder,
      onClick: isNaN(workOrder)
        ? undefined
        : { preset: 'zoom', then: () => navigate(`/loglist?work_order=${workOrder}`) }
    };
  });

  return <MapComponent fitBounds={true} markers={stations} mapTypeId={'satellite'} />;
}
