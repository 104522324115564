import { Button, Container, Form, Nav } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import loginStyle from '../styles/login.module.css';
import { useState } from 'react';
import { useAuth } from '../hooks/AuthProvider';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { login } = useAuth();

  function handleLogin(event: React.FormEvent<HTMLDivElement>) {
    event.preventDefault();
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('email', email);
    urlencoded.append('password', password);

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: headers,
      body: urlencoded,
      redirect: 'follow'
    };

    if (email && password) {
      fetch('https://tracy.softwerk.se/demo/api/treeIdUsers/userLogin', requestOptions)
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => {
              if (data.authentication.result) {
                login({
                  name: 'Jonny Edvardsson',
                  token: data.authentication.result.token.id,
                  id: data.authentication.result.user.id,
                  loggedIn: true,
                  company: 'Demo AB'
                });
              } else {
                alert('Invalid email or password !');
              }
            });
          } else {
            return response.json().then((data) => {
              // eslint-disable-next-line no-console
              console.error(data.error);
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }

  return (
    <div className={loginStyle.background} onSubmit={handleLogin}>
      <Container className={`${loginStyle.container} d-flex vh-100 align-items-center`}>
        <Header />
        <div className={loginStyle.main_div}>
          <h2 className={`${loginStyle.formTitle}`}>Login</h2>
          <Form>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label className={loginStyle.input}>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="email@tracyofsweden.com"
                value={email}
                required
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label className={loginStyle.input}>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                required
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicCheckbox">
              <Form.Check className={loginStyle.check} type="checkbox" label="Keep me signed in" />
            </Form.Group>
            <div className={loginStyle.btnDiv}>
              <Button className={`${loginStyle.loginBtn}`} type="submit">
                Login
              </Button>
            </div>
            <Nav.Item>
              <Nav.Link className={loginStyle.resetPassLink} eventKey="link">
                Forgot your password?
              </Nav.Link>
            </Nav.Item>
          </Form>
        </div>
        <Footer />
      </Container>
    </div>
  );
}
