import { useEffect, useRef } from 'react';
import { Stations } from '../../routes/LogListInfo';
import { getBoundForMarkers } from '../../utils';

export interface StationMarker {
  log_id?: string;
  order_id?: number | string;
  workorder?: number;
  position: google.maps.LatLngLiteral;
  type: Stations;
  onClick?: { preset: 'zoom'; then?: () => void } | (() => void);
}

export default function MapComponent({
  center,
  zoom = 12,
  markers = [],
  mapTypeId = 'roadmap',
  fitBounds = false
}: {
  center?: google.maps.LatLngLiteral | null;
  zoom?: number;
  markers?: StationMarker[];
  mapTypeId?: 'satellite' | 'roadmap' | 'terrain' | undefined;
  fitBounds?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current != null) {
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        mapTypeId
      });

      if (fitBounds) {
        map.fitBounds(getBoundForMarkers(markers));
      }

      markers.forEach((markerOptions) => {
        const marker = new window.google.maps.Marker({
          position: markerOptions.position,
          map: map,
          title: markerOptions.type
        });

        if (markerOptions.onClick != null) {
          if ('preset' in markerOptions.onClick) {
            marker.addListener('click', () => {
              const infowindow = new google.maps.InfoWindow({
                content: `Workorder: ${markerOptions.workorder?.toString()}`,
                ariaLabel: markerOptions.workorder?.toString()
              });

              map.setZoom(16);
              map.setCenter(markerOptions.position);

              infowindow.open({
                anchor: marker,
                map
              });

              if (markerOptions.onClick != null && 'then' in markerOptions.onClick) {
                if (markerOptions.onClick.then != null)
                  marker.addListener('click', markerOptions.onClick.then);
              }
            });
          } else {
            marker.addListener('click', markerOptions.onClick);
          }
        }
      });
    }
  });

  return <div ref={ref} id="map" />;
}
