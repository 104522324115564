import { Button, Container, Form } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import loginStyle from '../styles/login.module.css';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
  const navigate = useNavigate();

  function goToLogin() {
    navigate('/login');
  }

  return (
    <div className={loginStyle.background}>
      <Container className={`${loginStyle.container} d-flex vh-100 align-items-center`}>
        <Header />
        <div className={loginStyle.main_div}>
          <h2 className={`${loginStyle.formTitle}`}>
            {' '}
            You are now <br /> logged out!
          </h2>
          <Form>
            <div className={loginStyle.btnDivLogout}>
              <Button className={`${loginStyle.logoutBtn}`} onClick={goToLogin}>
                Login
              </Button>
            </div>
          </Form>
        </div>
        <Footer />
      </Container>
    </div>
  );
}
