import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthProvider';

interface Props {
  children: JSX.Element;
}

export const ProtectedRoute = (props: Props) => {
  const { user } = useAuth();

  if (user == null) {
    return <Navigate to="/login" />;
  }
  return props.children;
};
